import * as Sentry from '@sentry/vue'

const config = useRuntimeConfig()
if (config.public.environment === 'production') {
  Sentry.init({
    dsn: 'https://687d348ac88672b75ba5028c68770451@o4506154787405824.ingest.us.sentry.io/4508091061567488',
    // Tracing
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Not Found',
      'NetworkError',
      'Failed to fetch dynamically imported module',
      'Importing a module script failed',
      'Loading chunk',
      'Failed to fetch',
      'Connection to Indexed Database server lost',
      'お探しのページは見つかりませんでした。',
      /ChunkLoadError/,
      /Network Error/,
      /Error fetching app manifest/,
      /builds\/meta.*\.json.*404/,
      /Failed to fetch this Firebase app's measurement ID/,
      "TypeError: undefined is not an object (evaluating 'x.default')",
      "TypeError: undefined is not an object (evaluating 'r.default')",
      'Hydration completed but contains mismatches',
      // Firebaseの永続化エラー（Safari特有）
      {
        test: (e: string) => {
          return (
            /TypeError/.test(e) &&
            /Right side of assignment cannot be destructured/.test(e) &&
            /@firebase\/auth.*IndexedDBLocalPersistence/.test(e)
          )
        },
      } as unknown as string,
      // IndexedDBのエラー（Safari特有）
      {
        test: (e: string) => {
          return (
            /Error/.test(e) &&
            /InvalidStateError: Object store cannot be found in the database/.test(
              e
            )
          )
        },
      } as unknown as string,
      // マウスイベントとタッチイベントの混在で発生する既知のエラー
      {
        test: (e: string) => {
          return (
            /TypeError/.test(e) &&
            /undefined is not an object \(evaluating 'i\.touches\[0\]'\)/.test(
              e
            ) &&
            /vue3-carousel/.test(e)
          )
        },
      } as unknown as string,
      // Vueのハイドレーション中に発生するエラーを無視する
      {
        test: (e: string) => {
          return (
            /TypeError/.test(e) &&
            /null is not an object \(evaluating 'e\.nodeType'\)/.test(e) &&
            /runtime-core/.test(e)
          )
        },
      } as unknown as string,
      // Firebaseのインストールエラー（Safari特有）
      {
        test: (e: string) => {
          return (
            /SecurityError/.test(e) &&
            /The operation is insecure/.test(e) &&
            /@firebase\/installations/.test(e)
          )
        },
      } as unknown as string,
      // Nuxtのルーティングエラー（Safari特有）
      {
        test: (e: string) => {
          return (
            /TypeError/.test(e) &&
            /Cannot read properties of undefined \(reading 'default'\)/.test(
              e
            ) &&
            /nuxt\/dist\/pages\/runtime\/plugins\/router/.test(e)
          )
        },
      } as unknown as string,
      // IndexedDBのエラー（Safari特有）
      {
        test: (e: string) => {
          return (
            /UnknownError/.test(e) &&
            /Attempt to delete range from database without an in-progress transaction/.test(
              e
            )
          )
        },
      } as unknown as string,
      // Firebaseの認証リクエスト中のネットワークエラー
      {
        test: (e: string) => {
          return (
            /FirebaseError/.test(e) && /auth\/network-request-failed/.test(e)
          )
        },
      } as unknown as string,
      // Nuxtのコンポーネント解決エラー
      {
        test: (e: string) => {
          return (
            /Error/.test(e) && /Couldn't resolve component "default" at/.test(e)
          )
        },
      } as unknown as string,
      // モジュールのインポートエラー
      {
        test: (e: string) => {
          return (
            /TypeError/.test(e) && /Importing a module script failed/.test(e)
          )
        },
      } as unknown as string,
      // IntersectionObserverのサポートに関するエラー
      {
        test: (e: string) => {
          return (
            /ReferenceError/.test(e) &&
            /Can't find variable: IntersectionObserver/.test(e)
          )
        },
      } as unknown as string,
      // ResizeObserverのエラー
      {
        test: (e: string) => {
          return (
            /ReferenceError/.test(e) &&
            /Can't find variable: ResizeObserver/.test(e)
          )
        },
      } as unknown as string,
      // Wilico APIへのリクエストが400エラー（Bad Request 外部サービスの一時的な検証エラー
      {
        test: (e: string) => {
          return (
            /AxiosError/.test(e) &&
            /Request failed with status code 400/.test(e) &&
            /wilico-api.com\/sites\/validate/.test(e)
          )
        },
      } as unknown as string,
      // Cross-Origin正常系一時的エラー
      {
        test: (e: string) => {
          return (
            /SecurityError/.test(e) &&
            /Blocked a frame with origin.*from accessing a cross-origin frame/.test(
              e
            )
          )
        },
      } as unknown as string,
      // Firefoxブラウザ特有のストレージアクセスエラー
      {
        test: (e: string) => {
          return (
            /NS_ERROR_FILE_NOT_FOUND/.test(e) && /File error: Not found/.test(e)
          )
        },
      } as unknown as string,
    ],
    denyUrls: [
      // Sentryの内部エラーを除外
      /node_modules\/@sentry\/utils/,
      /node_modules\/nuxt\/dist\/app\/components/,
      /node_modules\/vuetify\/lib\/components/,
      /<anonymous>/,
    ],
    beforeSend(event) {
      if (window.__NUXT__?.cdnGenerateCrawler === true) {
        return null // CDNのクローラーからのエラーは報告しない
      }

      if (
        event.exception?.values?.some((e) => {
          // 404エラーを無視
          const is404Error = /404/.test(e.value || '')
          // 400エラーを無視
          const is400Error = /400/.test(e.value || '')

          return (
            (e.mechanism?.type === 'onunhandledrejection' ||
              e.mechanism?.type === 'generic' ||
              e.type === 'Error') &&
            (is404Error || is400Error)
          )
        })
      ) {
        return null
      }

      return event
    },
  })
}
